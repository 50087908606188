import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import banner from "../images/reisbanner.png"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  banner: {
    width: "80%",
    margin: "0 10%"
  }
}))


const ReisPage = () => {
  const classes = useStyles()
  return <Layout>
    <SEO title="Reis" />
    <Grid container spacing={0}>
      <Grid item xs={12} sm={7}>
        <p>Waerde Lords,</p>
        <p>HPH Claymore viert alweer haar 4e lustrum en als grootste activiteit hoort daar natuurlijk de lustrumreis naar Edinburgh bij. Wij zullen op 28 mei in de avond rond half zeven vertrekken vanaf Rotterdam/Amsterdam naar Edinburgh en we komen op maandag 1 juni(2e pinksterdag) rond 10 uur weer aan in Rotterdam/Amsterdam. <b>Vliegtickets zullen we 6 december boeken</b>, dus geef hiervoor aan of je erbij bent bij de lustrumcommissie. Vliegtickets zullen van uw lustrumtegoed af gaan, als je niet genoeg hebt nemen we contact met je op.</p>
        <p>Wij verblijven midden in het centrum van Edinburgh in een hostel, dus alle faciliteiten zullen voor de Lords binnen handbereik zijn. We gaan ervan uit dat je bij het hostel bent tenzij anders aangegeven. </p>
        <p>Het hoogtepunt van de reis is het Heerendiner in het Balmoral op 31 mei. In het Balmoral zal traditiegetrouw het nieuwe bestuur worden gepresenteerd, namelijk het 21e bestuur der HPH Claymore. Daarnaast wordt er gedineerd, veel gedronken en natuurlijk geheerst. De andere activiteiten zullen binnenkort gepresenteerd worden, houd je mail/whatsapp in de gaten. Wij koesteren dan ook de verwachting u allen te zien op deze gruwelijke reis.</p>

      </Grid>
      <Grid item xs={12} sm={5}>
        <img src={banner} alt="Banner" className={classes.banner} />
      </Grid>
    </Grid>

  </Layout>
}

export default ReisPage
